import React from "react"
import Hero from "@/components/sections/hero"
import BlogHero from "@/components/sections/BlogHero"
import DesktopAdvantages from "./sections/DesktopAdvantages/DesktopAdvantages"
import DiscoverCarousel from "./sections/DiscoverCarousel/DiscoverCarousel"
import PricingSection from "./sections/PricingSection/PricingSection"
import ToolSection from "./sections/ToolSection/ToolSection"
import FAQ from "./sections/FAQ/FAQ"
import WhyXpensme from "./sections/WhyXpensme/WhyXpensme"
import Contacts from "./sections/Contacts/Contacts"
import Feedbacks from "./sections/Feedbacks/Feedbacks"
import Differences from "./sections/Differences/Differences"
import AboutUs from "./sections/AboutUs/AboutUs"
import AboutCompany from "./sections/AboutCompany/AboutCompany"
import Experience from "./sections/Experience/Experience"
import HowItWorksHero from "./sections/HowItWorksHero"
import Process from "./sections/Process/Process"
import ContactUs from "./sections/ContactUs/ContactUs"

import "react-multi-carousel/lib/styles.css"
import HowItWorksTiles from "./sections/HowItWorksTiles/HowItWorksTiles"
import Markdown from "./sections/Markdown/Markdown"
import ThankYou from "./sections/ThankYou/ThankYou";

// Map Strapi sections to section components
const sectionComponents = {
  "sections.home-hero": Hero,
  "sections.discover-carousel": DiscoverCarousel,
  "sections.desktop-section": DesktopAdvantages,
  "sections.tool-section": ToolSection,
  "sections.pricing-section": PricingSection,
  "sections.faq": FAQ,
  "sections.contact-section": Contacts,
  "sections.why-xpensme-section": WhyXpensme,
  "sections.feedbacks": Feedbacks,
  "sections.xpensme-differences": Differences,
  "sections.blog-hero": BlogHero,
  "sections.how-it-works-hero": HowItWorksHero,
  "sections.about-us": AboutUs,
  "sections.about-company": AboutCompany,
  "sections.experience": Experience,
  "sections.progress": Process,
  "sections.how-it-works-tiles": HowItWorksTiles,
  "sections.contact-us": ContactUs,
  "sections.markdown": Markdown,
  "sections.thank-you": ThankYou
  ,
}

// Display a section individually
const Section = ({ sectionData }) => {
  // Prepare the component
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component]

  if (!SectionComponent) {
    // No matching component for this page section
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} />
}

// Display the list of sections
const Sections = ({ sections }) => {
  return (
    <>
      {sections.map((section, i) => (
        <Section
          sectionData={section}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </>
  )
}

export default Sections
