import React, { useState } from "react"

import "./FAQ.sass"

const FAQ = ({ data }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(data.FAQQuestion[0]);
  
  return (
    <section className="FAQSectionWrapper">
      <div className="FAQ_title_wrapper">
        <h2 className="FAQSectionWrapper_title">{data.title}</h2>
        <div className="FAQSectionWrapper_question">
          <h4 className="FAQSectionWrapper_question_title">
            {selectedQuestion.title}
          </h4>
          <span>-</span>
        </div>
        <p className="FAQSectionWrapper_question_answer">
          {selectedQuestion.answer}
        </p>
      </div>
      <ul className="FAQSectionWrapper_list">
        {data.FAQQuestion.map((item, index) => {
          return (
            <li
              key={item.id}
              onClick={() => {
                setSelectedQuestion(item);
              }}
              style={{
                marginBottom:
                  data.FAQQuestion.length - 1 === index ? 0 : "20px",
              }}
            >
              <p>{item.title}</p>
              <span>{selectedQuestion.id === item.id ? "-" : "+"}</span>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default FAQ
