import React from "react"

import "./AboutCompany.sass"

const AboutCompany = ({ data }) => {
  return (
    <section className="AboutCompanySectionWrapper">
      <div className="AboutCompanySectionWrapper_leftSide">
        <h2>{data.Title}</h2>
        <ul className="AboutCompanySectionWrapper_advantages">
          {data.advantages.map(item => {
            return (
              <li key={item.id} className="AboutCompanyAdvantageItem">
                <div className="AboutCompanyAdvantageItem_header">
                  <img
                    className="AboutCompanyAdvantageItem_icon"
                    src={item.icon.url}
                    alt="icon"
                  />
                  <h4>{item.title}</h4>
                </div>
                <p>{item.subtitle}</p>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="AboutCompanySectionWrapper_rightSide">
        <img src={data.image.url} alt="meeting" />
      </div>
    </section>
  )
}

export default AboutCompany
