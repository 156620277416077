import React from "react"
import mark from "../../images/mark.png"
import ButtonLink from "./button-link"

const PlanItem = ({ data, isLast }) => {
  return (
    <li className="plan_wrapper" style={{
      margin: isLast ? 0 : "0 30px 10px 0"
    }}>
      <div
        className="plan_header"
        style={{
          background: data.isRecommended ? "rgba(150, 114, 254, 0.2)" : "white",
        }}
      >
        <h5 className="plan_header_period">{data.period}</h5>
        <h3 className="plan_header_title">{data.Title}</h3>
        <p className="plan_header_total">
          {data.totalAmount} {data.currency} {data.perPeriod}
        </p>
      </div>
      <div
        className="plan_details"
        style={{
          background: data.isRecommended ? "rgba(150, 114, 254, 0.2)" : "white",
        }}
      >
        <h5 className="plan_details_title">
          Prepaid Subscription price consists of:
        </h5>
        <ul className="plan_details_list">
          <li className="plan_details_list_title_wrapper">
            <h5 className="plan_details_list_title">Plastic:</h5>
            <p className="plan_details_list_subtitle">{data.plasticAmount}</p>
          </li>
          {data.plasticBenefits.map(item => {
            return (
              <li className="plan_details_list_item" key={item}>
                <img
                  className="plan_details_list_image"
                  src={mark}
                  alt="mark"
                />
                <p className="plan_details_list_value">{item}</p>
              </li>
            )
          })}
        </ul>
        <ul className="plan_details_list">
          <li className="plan_details_list_title_wrapper">
            <h5 className="plan_details_list_title">Virtual</h5>
            <p className="plan_details_list_subtitle">{data.virtualAmount}</p>
          </li>
          {data.virtualBenefits.map(item => {
            return (
              <li className="plan_details_list_item" key={item}>
                <img
                  className="plan_details_list_image"
                  src={mark}
                  alt="mark"
                />
                <p className="plan_details_list_value">{item}</p>
              </li>
            )
          })}
        </ul>
        <div className="plan_wrapper_total">
          <h5 className="plan_wrapper_total_title">Total</h5>
          <p className="plan_wrapper_total_subtitle">{data.totalCardsAmount}</p>
        </div>
        <div className="plan_wrapper_buttonWrapper">
          <ButtonLink button={data.Button} />
        </div>
      </div>
    </li>
  )
}

export default PlanItem
