import React from "react"
import ButtonLink from "../../elements/button-link"

import "./AboutUs.sass"

const AboutUs = ({ data }) => {
  return (
    <section className="AboutUsSectionWrapper">
      <div className="AboutUsLeftSide">
        <ul className="AboutUsLeftSide_list">
          {data.AboutUsCard.map(item => {
            return (
              <li key={item.id}>
                <img src={item.photo.url} alt="photo" />
                <div className="AboutUsLeftSide_list_title">
                  <p>{item.title}</p>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="AboutUsRightSide">
        <h2>{data.Title}</h2>
        <p>{data.Subtitle}</p>
        {data.Demo.map(button => (
          <ButtonLink button={button} key={button.id} />
        ))}
      </div>
    </section>
  )
}

export default AboutUs
