import React from "react"

import "./ToolSection.sass"
import ButtonLink from "../../elements/button-link"
import Video from "../../elements/video"

const ToolSection = ({ data }) => {
  return (
    <section className="ToolSectionWrapper">
      <div className="ToolSectionWrapper_header">
        <h3 className="ToolSectionWrapper_title">{data.title}</h3>
        <ButtonLink button={data.Buttons} key={data.Buttons.id} />
      </div>
      <div className="ToolSectionWrapper_mediaWrapper">
        <div className="ToolSectionWrapper_mediaWrapper_video">
          <Video
            media={data.video}
            playerParams={{
              height: "100%",
            }}
          />
        </div>
        <img
          src={data.image.url}
          alt="image"
          className="ToolSectionWrapper_image"
        />
      </div>
    </section>
  )
}

export default ToolSection
