import React from "react"
import Image from "../../image"

import "./HowItWorksTiles.sass"

const HowItWorksTiles = ({ data }) => {
  return (
    <main className="how-it-works-tiles">
      {data.Tile.map((t, i) => {
        return (
          <div className="how-it-works-tile" key={t.id}>
            <div
              className="how-it-works-tile_left"
              style={{ order: i % 2 === 0 ? 1 : -1 }}
            >
              <Image media={t.cover} />
            </div>

            <div className="how-it-works-tile_right">
              <h1 className="how-it-works-tile_title">{t.title}</h1>
              <p className="how-it-works-tile_description">{t.description}</p>

              <ul className="how-it-works-tile-points">
                {t.TilePoint.map(p => (
                  <li className="how-it-works-tile-point" key={p.id}>
                    <div className="how-it-works-tile-point_title">
                      <Image media={p.icon} />{p.title}
                    </div>
                    <p className="how-it-works-tile-point_description">{p.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
      })}
    </main>
  )
}

export default HowItWorksTiles
