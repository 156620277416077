import React from "react"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

import "./ContactUs.sass"

const ContactUs = ({ data }) => {
  const Map = compose(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${data.googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `100%`, width: "100%" }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
  ))

  return (
    <section className="ContactUsSectionWrapper">
      <div className="ContactUsContainer">
        <div className="MapWrapper">
          <Map lat={Number(data.lat)} lng={Number(data.lng)} />
        </div>
        <div className="ContactUsSectionContainer">
          <h3>{data.contactTitle}</h3>
          <form
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
            className="ContactsForm"
          >
            <input type="hidden" name="oid" value="00D5i000005lFNx" />
            <input type="hidden" name="retURL" value={data.RetURL} />
            {data.ContactsInput.map(item => {
              return (
                <div
                  key={item.id}
                  className="ContactsForm_inputWrapper"
                  style={{
                    width: item.isLastInput ? "100%" : "50%",
                  }}
                >
                  <label
                    htmlFor={item.form_key}
                    className="ContactsForm_inputWrapper_label"
                  >
                    {item.title}
                  </label>
                  <input
                    required={!item.isOptional}
                    id={item.form_key}
                    className="ContactsForm_input"
                    name={item.form_key}
                    type="text"
                  />
                </div>
              )
            })}
            <div className="ContactsSectionWrapper_buttonWrapper">
              <button type="submit" className="button-link">
                Contact me
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="wrapper">
        <div className="ContactUsFooterWrapper">
          <h3>{data.bottomTitle}</h3>
          <ul className="ContactFooterList">
            {data?.contactItem?.map(item => {
              const href = item.url ? `${item.type}:${item.url.trim()}` : ""
              return (
                <a
                  href={href}
                  className="ContactFooterList_link"
                  style={{
                    cursor: item.url ? "pointer" : "default",
                    pointerEvents: !item.url && "none",
                  }}
                >
                  <li key={item.id}>
                    <img src={item.icon?.url} alt="icon" />
                    <div className="ContactFooterListTitle">
                      <h4>{item.title}</h4>
                      <p>{item.subtitle}</p>
                    </div>
                  </li>
                </a>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
