import React from "react"
import PropTypes from "prop-types"
import { Player, BigPlayButton } from "video-react"
import { getStrapiMedia } from "@/utils/media"
import { mediaPropTypes } from "@/utils/types"

const Video = ({ media, autoPlay = false, playerParams = {} }) => {
  const fullVideoUrl = getStrapiMedia(media.url)

  return (
    // TODO: Videos must have captions track
    // eslint-disable-next-line
    <Player
      src={fullVideoUrl}
      autoPlay={autoPlay}
      type={media.mime}
      {...playerParams}
    >
      <BigPlayButton position="center" />
    </Player>
  )
}

Video.propTypes = {
  media: mediaPropTypes.isRequired,
  autoPlay: PropTypes.bool,
}

export default Video
