import React from "react"

import "./Contacts.sass"

const Contacts = ({ data }) => {
  return (
    <section className="ContactsSectionWrapper">
      <div className="ContactsSectionContainer">
        <div className="Contacts_left_side">
          <h3>{data.title}</h3>
          {data?.paragraph?.map(item => {
            return (
              <p key={item.id} className="Contacts_left_side_paragraph">
                {item.text}
              </p>
            )
          })}
        </div>
        <form
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          className="ContactsForm"
        >
          <input type="hidden" name="oid" value="00D5i000005lFNx" />
          <input type="hidden" name="retURL" value={data.retURL} />
          {data.ContactInput.map(item => {
            return (
              <div
                key={item.id}
                className="ContactsForm_inputWrapper"
                style={{
                  width: item.isLastInput ? "100%" : "50%",
                }}
              >
                <label
                  htmlFor={item.form_key}
                  className="ContactsForm_inputWrapper_label"
                >
                  {item.title}
                </label>
                <input
                  required={!item.isOptional}
                  id={item.form_key}
                  className="ContactsForm_input"
                  name={item.form_key}
                  type="text"
                />
              </div>
            )
          })}
          <div className="ContactsSectionWrapper_buttonWrapper">
            <button type="submit" className="ContactsSectionWrapper_button button-link">
              Contact me
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contacts
