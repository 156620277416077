import Markdown from "react-markdown"
import React from "react"

import "./Markdown.sass"

const MarkdownSection = ({ data }) => {
  return (
    <section className="wrapper markdown-wrapper">
      <Markdown allowDangerousHtml source={data.content} />
    </section>
  )
}

export default MarkdownSection
