import React from "react"
import ButtonLink from "../../elements/button-link"

import "./WhyXpensme.sass"

const WhyXpensme = ({ data }) => {
  return (
    <section className="WhyXpensmeSection">
      {data.BenefitsBlock.map((item, index) => {
        return (
          <div
            className="WhyXpensmeWrapper"
            key={item.id}
            style={{
              flexDirection: index % 2 !== 0 ? "row-reverse" : "row",
            }}
          >
            <div className="WhyXpensme_leftSide">
              <img
                src={item.image.url}
                alt="image"
                className="WhyXpensme_leftSide_image"
              />
            </div>
            <div
              className="WhyXpensme_rightSide"
              style={{
                backgroundColor: item.backgroundColor,
              }}
            >
              <div className="WhyXpensme_textWrapper">
                <h2 className="WhyXpensme_rightSide_title">{item.title}</h2>
                {item.text.map(element => {
                  return (
                    <p key={element.id} className="WhyXpensme_rightSide_text">
                      {element.text}
                    </p>
                  )
                })}
              </div>
              {item.showDemoButton && <ButtonLink button={item.demo} />}
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default WhyXpensme
