import React from "react"
import './ThankYou.sass'

const ThankYou = () => {
    return (
        <section className="thankYou">
            <div className="thankYou_content">
                <div className="thankYou_content_title">
                    <h3>Thank you</h3>
                    <p>We’ve received your message and will be in touch shortly.</p>
                </div>
            </div>
        </section>
    )
}

export default ThankYou
