import React from "react"

const SubscriptionTable = ({ data }) => {
  return (
    <table className="SubscriptionTable">
      <thead>
        <tr>
          {data?.SubscriptionTable?.subscriptionTableHeader.map((item, index) => {
            return <td key={item}>{item}</td>
          })}
        </tr>
      </thead>
      <tbody>
        {data.SubscriptionTable?.SubscriptionTableRows?.map(item => {
          const keys = Object.keys(item.subscriptionAmounts[0]);
          return (
            <tr
              key={item.id}
              style={{
                backgroundColor: item.isRecomended
                  ? "rgba(145, 105, 247, 0.1)"
                  : "",
              }}
            >
              <td colSpan={0} className="plan_cell">
                <div className="title_bold">{item.title}</div>
                <div>{item.cardAmount}</div>
              </td>
              <td className="bordered_cell">
                {keys.map(key => <div key={key}>{key}</div>)}
              </td>
              {item.subscriptionAmounts.map(element => {
                return (
                  <td key={item.id} className="bordered_cell">
                    <div>{element[keys[0]]}</div>
                    <div>{element[keys[1]]}</div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default SubscriptionTable
