import React from "react"
import closeSquare from "../../../images/closeSquare.png"
import checked from "../../../images/checked.png"

import "./Differences.sass"

const Differences = ({ data }) => {
  return (
    <section className="DifferencesSectionWrapper">
      <h2 className="DifferencesSectionWrapper_title">{data.title}</h2>
      <table className="DifferencesTable">
        <thead className="DifferencesTable_head">
          <tr>
            {data.TableHead.map(item => {
              return <td key={item.id}>{item.title}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {data.DifferenceTableRow.map((item, index) => {
            return (
              <tr key={item.id} className="Differences_tableRow">
                <td
                  style={{
                    borderBottom:
                      index !== data.DifferenceTableRow.length - 1
                        ? "1px solid white"
                        : 0,
                  }}
                >
                  <div className="Differences_tableTitle">{item.title}</div>
                  <div className="Differences_tableSubtitle">{item.subtitle}</div>
                </td>
                {item.cell.map(element => {
                  return (
                    <td
                      key={element.id}
                      align="center"
                      style={{
                        borderBottom:
                          index !== data.DifferenceTableRow.length - 1
                            ? "1px solid white"
                            : 0,
                      }}
                    >
                      <img
                        src={element.checked ? checked : closeSquare}
                        alt="icon"
                      />
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

export default Differences
