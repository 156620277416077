import React, { useRef } from "react"
import arrow from "../../../images/Vector.png"
import Carousel from "react-multi-carousel"

import "./DiscoverCarousel.sass"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1300, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const DiscoverCarousel = ({ data }) => {
  const ref = useRef(null)

  return (
    <section className="sectionWrapper">
      <div className="leftSide">
        <div className="invisibleWrapper" />
        <h3 className="leftSide_title">Discover our features</h3>
        <div className="leftSide_buttonWrapper">
          <button onClick={() => {
              ref.current.previous()
          }} className="leftSide_buttonWrapper_button">
            <img src={arrow} alt="arrow" />
          </button>
          <button onClick={() => {
             ref.current.next()
          }} className="leftSide_buttonWrapper_button">
            <img className="rightArrow" src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        ref={ref}
        containerClass="carousel-container"
        arrows={false}
        itemClass={"CarouselList_item"}
      >
        {data.CardCarousel.map(item => {
          return (
            <div className="cardItem" key={item.id}>
              <img className="itemImage" src={item.icon.url} alt="icon" />
              <h4 className="CarouselList_item_title">{item.title}</h4>
              <p className="CarouselList_item_subtitle">{item.subtitle}</p>
            </div>
          )
        })}
      </Carousel>
    </section>
  )
}

export default DiscoverCarousel
