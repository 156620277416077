import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Image from "../image"

const BlogHero = ({ data }) => {
  const {
    allStrapiArticle: { nodes: articles },
  } = useStaticQuery(graphql`
    query BlogArticlesQuery {
      allStrapiArticle(
        filter: { published_at: { ne: null } }
        sort: { fields: updated_at, order: DESC }
      ) {
        nodes {
          id
          url
          title
          description
          mainImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          article_author {
            firstName
            lastName
            position
            avatar {
              url
            }
          }
        }
      }
    }
  `)
  const [heroArticle, ...gridArticles] = articles

  const getDescription = (input = "") => {
    const description = input.length > 170 ? `${input.slice(0, 167)}...` : input
    return description.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      )
    })
  }

  return (
    <>
      <main className="blog-hero">
        <div className="wrapper">
          <div className="blog-hero_content">
            <div className="blog-hero_left">
              <Image media={data.image} />
            </div>
            <div className="blog-hero_right">
              <h1 className="blog-hero_title">{data.title}</h1>
              <p className="blog-hero_description">
                {data.description.split("\n").map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  )
                })}
              </p>
            </div>
          </div>
        </div>
      </main>
      {articles.length && (
        <section className="blog-articles">
          <div className="wrapper">
            <Link
              className="article-card blog-articles_main"
              to={`/blog/${heroArticle.url}`}
            >
              <div className="article-card_left">
                {heroArticle.mainImage && (
                  <Image
                    className="article-card_image"
                    media={heroArticle.mainImage}
                  />
                )}
              </div>
              <div className="article-card_right">
                <div className="article-card_info">
                  <div className="article-card_info-title">
                    {heroArticle.title}
                  </div>
                  <div className="article-card_info-desc">
                    {heroArticle.description}
                  </div>
                </div>
                {heroArticle.article_author && (
                  <div className="article_author">
                    <Image
                      media={heroArticle.article_author.avatar}
                      style={{
                        width: "44px",
                        height: "44px",
                        borderRadius: "50%",
                        marginRight: "14px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="article_author_info">
                      <div className="article_author_name">
                        {heroArticle.article_author.firstName}{" "}
                        {heroArticle.article_author.lastName}
                      </div>
                      <div className="article_author_position">
                        {heroArticle.article_author.position}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Link>
          </div>
          {gridArticles.length && (
            <div className="wrapper blog-articles_list">
              {gridArticles.map(item => {
                return (
                  <div className="article-card" key={item.id}>
                    <Link to={`/blog/${item.url}`}>
                      <Image
                        className="article-card_image"
                        media={item.mainImage}
                      />
                      <div className="article-card_info">
                        <div className="article-card_info-title">
                          {item.title}
                        </div>
                        <div className="article-card_info-desc">
                          {getDescription(item.description)}
                        </div>
                      </div>
                      {item.article_author && (
                        <div className="article_author">
                          <Image
                            media={item.article_author.avatar}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                              marginRight: "10px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="article_author_info">
                            <div className="article_author_name">
                              {item.article_author.firstName}{" "}
                              {item.article_author.lastName}
                            </div>
                            <div className="article_author_position">
                              {item.article_author.position}
                            </div>
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default BlogHero
