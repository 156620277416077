import React from "react"

import "./DesktopAdvantages.sass"

const DesktopAdvantages = ({ data }) => {
  return (
    <section className="DesktopSectionWrapper">
      <img className="DesktopImage" src={data.mainImage.url} alt="image" />
      <div className="AdvantagesWrapper">
        <h3 className="AdvantagesWrapper_title">{data.title}</h3>
        <ul className="AdvantagesList">
          {data.Advantages.map(item => {
            return (
              <li key={item.id} className="AdvantageItem">
                <div className="AdvantageItem_header">
                  <img
                    className="AdvantageItem_icon"
                    src={item.icon.url}
                    alt="icon"
                  />
                  <h4>{item.title}</h4>
                </div>
                <p>{item.subtitle}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default DesktopAdvantages
