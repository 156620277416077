import React, { useRef } from "react"
import arrow from "../../../images/Vector.png"
import quotes from "../../../images/quotes.png"
import Carousel from "react-multi-carousel"

import "./Feedbacks.sass"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1600, min: 1225 },
    items: 2,
  },
  "tablet-sm": {
    breakpoint: { max: 1225, min: 980 },
    items: 1,
  },
  "mobile-lg": {
    breakpoint: { max: 980, min: 600 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 750, min: 550 },
    items: 2,
  },
  "mobile-sm": {
    breakpoint: { max: 550, min: 0 },
    items: 1,
  },
}

const Feedbacks = ({ data }) => {
  const feedbackRef = useRef(null)
  return (
    <section className="FeedbacksSectionWrapper">
      <div className="feedback_leftSide">
        <div className="invisibleWrapper" />
        <h3 className="feedback_leftSide_title">{data.title}</h3>
        <div className="feedback_leftSide_buttonWrapper">
          <button
            onClick={() => {
              feedbackRef.current.previous()
            }}
            className="feedback_leftSide_buttonWrapper_button"
          >
            <img src={arrow} alt="arrow" />
          </button>
          <button
            onClick={() => {
              feedbackRef.current.next()
            }}
            className="feedback_leftSide_buttonWrapper_button"
          >
            <img className="rightArrow" src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        ref={feedbackRef}
        containerClass="feedback-carousel-container"
        arrows={false}
        itemClass={"feedbackCarouselList_item"}
      >
        {data.FeedbackItem.map(item => {
          return (
            <div className="feedbackCardItem" key={item.id}>
              <div className="feedbackCardItem_header">
                <h4 className="feedbackCarouselList_item_title">
                  {item.title}
                </h4>
                <img className="feedback_itemImage" src={quotes} alt="icon" />
              </div>
              <p className="feedbackCarouselList_item_subtitle">
                {item.feedback}
              </p>
              <div className="feedbackCarouselList_item_imageWrapper">
                {item.signature && <img src={item.signature.url} alt="icon" />}
              </div>
            </div>
          )
        })}
      </Carousel>
    </section>
  )
}

export default Feedbacks
