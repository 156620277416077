import React from "react"

const Process = ({ data }) => {
  return (
    <section class="process">
      <div className="process-wrapper">
        <div className="process-main">
          <div className="process-title">{data.title}</div>
          <div className="process-description">{data.description}</div>
        </div>
        {data.Point.map((p, i) => (
          <div className="process-point" key={p.id} data-index={i + 1}>
            <h3 className="process-point_step">{`0${i + 1}`}</h3>
            <div className="process-point_wrapper">
              <div className="process-point_title">{p.title}</div>
              <div className="process-point_description">{p.description}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Process
