import React from "react"
import ButtonLink from "../elements/button-link"
import { getStrapiMedia } from "@/utils/media"
import Image from "../image"
import Video from "../elements/video"

const Hero = ({ data }) => {
  return (
    <main className="hero">
      <div className="wrapper">
        <div className="hero_content">
          <div className="hero_left">
            <h1 className="hero_title">{data.title}</h1>
            <p className="hero_description">
              {data.description.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                )
              })}
            </p>
            <div className="hero_buttons">
              {data.buttons.map(button => (
                <ButtonLink button={button} key={button.id} />
              ))}
            </div>
          </div>
          <div className="hero_right">
            <div className="hero_video">
              <Video media={data.video} />
            </div>
          </div>
        </div>
        <div className="hero_partners">
          {data.partners.map(partner => (
            <div className="hero_partners_item">
              <Image
                key={partner.id}
                media={partner}
                className="hero_partners_image"
              />
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}

export default Hero
