import React from "react"
import PlanItem from "../../elements/plan"
import SubscriptionTable from "../../elements/subscription-table"

import "./PricingSection.sass"

const PricingSection = ({ data }) => {
  return (
    <section className="PricingSectionWrapper">
      <header className="PricingSectionWrapper_header">
        <h2 className="PricingSectionWrapper_title">{data.Title}</h2>
        <p className="PricingSectionWrapper_subtitle">{data.subtitle}</p>
      </header>
      <div className="PricingPlanWrapper">
        <ul className="PricingPlanWrapper_list">
          {data.PricingPlan?.map((item, index) => {
            return (
              <PlanItem
                key={item.id}
                data={item}
                isLast={data.PricingPlan.length - 1 === index}
              />
            )
          })}
        </ul>
      </div>
      <div>
        <SubscriptionTable data={data} />
      </div>
    </section>
  )
}

export default PricingSection
