import React from "react"
import ButtonLink from "../elements/button-link"
import Image from "../image"

const HowItWorksHero = ({ data }) => {
  return (
    <main className="how-it-works-hero">
      <div className="how-it-works-hero_content">
        <div className="how-it-works-hero_left how-it-works-hero_images">
          <Image
            className="how-it-works-hero_images-main"
            media={data.backgroundImage}
          />
          <Image
            className="how-it-works-hero_images-middle"
            media={data.middleImage}
          />
        </div>

        <div className="how-it-works-hero_right">
          <h1 className="how-it-works-hero_title">{data.title}</h1>
          <p className="how-it-works-hero_description">
            {data.description.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </p>
          <div className="how-it-works-hero_buttons">
            {data.buttons.map(button => (
              <ButtonLink button={button} key={button.id} />
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default HowItWorksHero
