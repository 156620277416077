import React from "react"

import "./Experience.sass"

const Experience = ({ data }) => {
  return (
    <section className="ExperienceSectionWrapper">
      <div className="ExperienceSectionContainer">
        <div className="ExperienceSectionWrapper_leftSide">
          <div className="ExperienceSectionWrapper_leftSide_imageWrapper">
            {data.TopLeftImage && (
              <img src={data.TopLeftImage.url} alt="photo" />
            )}
            {data.BottomLeftImage && (
              <img src={data.BottomLeftImage.url} alt="photo" />
            )}
          </div>
          {data.RightImage && (
            <img src={data.RightImage.url} className="rightImage" alt="photo" />
          )}
        </div>
        <div className="ExperienceSectionWrapper_rightSide">
          <h2>{data.Title}</h2>
          <p className="ExperienceSectionWrapper_rightSide_subtitle">
            {data.Subtitle}
          </p>
          <ul className="ExperienceSectionWrapper_rightSide_list">
            {data.Statistic.map(item => {
              return (
                <li key={item.id}>
                  <p className="ExperienceSectionWrapper_amount">
                    {item.amount}
                  </p>
                  <p className="ExperienceSectionWrapper_subtitle">
                    {item.Subtitle}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {data.partners.length ? <ul className="ExperiencePartners">
        {data.partners.map(item => {
          return (
            <li key={item.id}>
              <img src={item.url} alt="company" />
            </li>
          )
        })}
      </ul> : null}
    </section>
  )
}

export default Experience
